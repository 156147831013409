<template>
  <SummaryReportsTable />
</template>

<script>
import SummaryReportsTable from "@/v3components/Datatables/SummaryReports/SummaryReportsTable"
export default {
  name: "SummaryReports",
  components: { SummaryReportsTable }
}
</script>
