<template>
  <FileUploadsTable />
</template>
<script>

import FileUploadsTable from "@/v3components/Datatables/FileUploadsTable"

export default {
  name: "FileUploads",
  components: { FileUploadsTable }
}
</script>
