<template>
  <div>
    <CContainer>
      <OutOfOfficeTable ref="OutOfOfficeDataTable" />
    </CContainer>
  </div>
</template>

<script>
import { ref } from "vue"
import OutOfOfficeTable from "@/v3components/Datatables/OutOfOfficeTable"

export default {
  name: "OutOfOffice",
  components: { OutOfOfficeTable },
  setup() {
    const OutOfOfficeDataTable = ref(null)

    const refreshDataTable = () => {
      OutOfOfficeDataTable.value.refreshDataTable()
    }

    return {
      OutOfOfficeDataTable,
      refreshDataTable
    }
  }
}
</script>
