<template>
  <ArchivedUsersDataTable />
</template>
<script>
import ArchivedUsersDataTable from "@/v3components/Datatables/ArchivedUsersDataTable"

export default {
  name: "ArchivedUsers",
  components: { ArchivedUsersDataTable }
}
</script>
