<template>
  <PassTransparencyDataTable />
</template>
<script>
import PassTransparencyDataTable from "@/v3components/Datatables/PassTransparencyDataTable"
export default {
  name: "PassTransparency",
  components: { PassTransparencyDataTable }
}
</script>
