<template>
  <LimitLocationsMaxCapTable />
</template>
<script>
import LimitLocationsMaxCapTable from "@/v3components/Datatables/LimitLocationsMaxCapTable"

export default {
  name: "LimitLocationMaxCap",
  components: {
    LimitLocationsMaxCapTable
  }
}
</script>
