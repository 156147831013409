<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <AdultsTable></AdultsTable>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import AdultsTable from "@/v3components/Datatables/AdultsTable"

export default {
  name: "TardyManagementAdults",
  components: { AdultsTable }
}
</script>
