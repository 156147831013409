<template>
  <div>
    <p>My locations</p>
    <SelectList
      :data="state.form.locations"
      select-placeholder="Add location"
      select-type="my_locations"
      counter-icon="ri-map-pin-2-fill"
      counter-icon-class="simple"
      :favorite-mode="true"
      :active-favorite-room-pin="isActiveAutoCheckInModule"
      first-column-name="Room"
      :last-column-name="
        isActiveAutoCheckInModule ? 'Student Check-in Pin' : ''
      "
      :invalid-feedback="state.serverErrors"
      @update-list-data="(value) => updateData(value)"
    />
    <div>
      <InfoBox
        v-if="state.serverRes"
        class="my-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <LoadingButton
      :is-loading="state.isProcessing"
      @click="submit()"
      class="mt-4 px-4"
      solid
      rounded
      >Save changes</LoadingButton
    >
  </div>
</template>

<script>
import { reactive, computed, watch, onMounted } from "vue"
import { useStore } from "vuex"
import SelectList from "@/v3components/shared/Form/SelectList"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import { forEach } from "lodash"

export default {
  name: "AddLocationsForm",
  components: {
    SelectList,
    InfoBox,
    LoadingButton
  },
  props: {},
  setup() {
    const store = useStore()
    const state = reactive({
      isProcessing: false,
      form: {
        locations: []
      },
      serverErrors: {},
      serverRes: null
    })

    onMounted(() => {
      store.dispatch("rooms/getUserAssignRooms").then(() => {
        userAssignedList()
      })
    })

    const isActiveAutoCheckInModule = computed(
      () => store.getters["schools/isActiveAutoCheckInModule"]
    )

    const userAsignedRooms = computed(
      () => store.getters["rooms/userAssignRooms"]
    )

    const roomsPinData = computed(() => store.getters["rooms/roomsPinData"])

    watch(
      () => roomsPinData.value,
      () => {
        userAssignedList()
      }
    )

    watch(
      () => userAsignedRooms.value,
      () => {
        userAssignedList()
      }
    )

    const userAssignedList = () => {
      if (!userAsignedRooms.value) return
      const newCollectionStore = userAsignedRooms.value.map((e) => {
        return {
          value: {
            id: e.room.id,
            type: e.type
          },
          label: e.room.name,
          icon: e.room.icon,
          pin: e.room_pin
        }
      })

      state.form.locations = newCollectionStore
    }

    const updateData = (value) => {
      state.form.locations = value
      state.serverErrors = {}
      state.serverRes = null
    }

    const submit = () => {
      const locationsCollection = []
      forEach(state.form.locations, (location) => {
        if (location) {
          const locationData = {
            room_id: location.value.id,
            pin: location.pin ? location.pin : null
          }
          locationsCollection.push(locationData)
        }
      })
      assignLocationsToUser(locationsCollection)
    }

    const assignLocationsToUser = (locations) => {
      state.isProcessing = true
      store
        .dispatch("rooms/assignRoomsToUser", { room_ids_and_pins: locations })
        .then(() => {
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          store.dispatch("rooms/getUserAssignRooms").then(() => {
            setTimeout(() => {
              setResponseInfoBox()
            }, 1800)
          })
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      isActiveAutoCheckInModule,
      updateData,
      submit
    }
  }
}
</script>
