<template>
  <div class="container">
    <div class="row limit-active-pass justify-content-center mt-4">
      <div class="col-md-8 v3-data-table full-height-table py-5 px-4">
        <LimitActivePassForm />
      </div>
    </div>
  </div>
</template>

<script>
import LimitActivePassForm from "@/v3components/Forms/LimitActivePassForm"

export default {
  name: "LimitActivePasses",
  components: {
    LimitActivePassForm
  }
}
</script>
