<template>
  <div>
    <CContainer>
      <StudentPassLimitsTable />
    </CContainer>
  </div>
</template>
<script>
import StudentPassLimitsTable from "@/v3components/Datatables/StudentPassLimitsTable"

export default {
  name: "LimitStudentPass",
  components: {
    StudentPassLimitsTable
  }
}
</script>
