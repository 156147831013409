<template>
  <CRow class="justify-content-center">
    <CSpinner v-if="state.isloading" color="primary" />
    <CCol v-if="activePass == null && !state.isloading" md="8" lg="6">
      <div>No Passes</div>
    </CCol>

    <CCol v-if="!state.isloading && activePass" md="12" lg="12">
      <div
        :class="[isPassLongRunning ? 'longRunning' : currentStatus]"
        class="active-pass rounded-30 pt-2"
        data-test-id="student-active-pass"
      >
        <div v-if="currentStatus == 'waiting'" class="p-4">
          <div
            class="bg-white p-3 rounded-30 d-flex justify-content-between align-items-center"
          >
            <div class="text-black-50">Currently waiting for approval!</div>
            <div class="d-flex align-items-center flex-shrink-0">
              <div
                v-if="activePass.type == 'STU' || activePass.type == 'TCH'"
                class="text-black-50 d-flex align-items-center"
                @click="openCancelModal()"
                data-test-id="student-active-pass-cancel-request"
              >
                <i class="flaticon-forbidden text-red me-1 mb-1" />Cancel
                Request
              </div>
              <div
                class="bg-red-gradient py-2 px-3 rounded-30 ms-2 text-center text-white"
                id="active-pass-time-in-request"
              >
                {{ timeInRequest }}
              </div>
            </div>
          </div>
          <div
            class="bg-white rounded-30 d-flex flex-column justify-content-between align-items-center m-0 mt-3"
          >
            <div v-if="comments.length" class="active-pass-comments-cnt">
              <div class="fw-bold pb-1">Comments:</div>
              <div
                v-for="(item, index) in comments"
                :key="index"
                class="active-pass-comments pb-1"
                data-test-id="student-active-pass-comment"
              >
                <strong
                  >[{{
                    $helpers.formatDate(item.created_at, "", "h:mm A")
                  }}]</strong
                >
                {{ item.comment }}
                <strong
                  >({{
                    item.user.first_name + " " + item.user.last_name
                  }})</strong
                >
              </div>
            </div>
            <div
              class="active-pass-timer bg-white rounded-30 d-flex align-items-center justify-content-center w-100 shadow text-red"
            >
              <span
                v-if="
                  activePass.flags &&
                  activePass.flags.auto_pass &&
                  !activePass.flags.auto_pass.is_valid
                "
                class="flaticon-hour-glass-2-optimized"
              >
                <span class="path1" /><span class="path2" /><span
                  class="path3"
                /><span class="path4" /><span class="path5" /><span
                  class="path6"
                /><span class="path7" /><span class="path8" /><span
                  class="path9"
                /><span class="path10" />
              </span>
              <div
                v-if="
                  activePass.flags &&
                  activePass.flags.auto_pass &&
                  activePass.flags.auto_pass.is_valid &&
                  (activePass.flags.auto_pass.mode == 'start_and_stop' ||
                    activePass.flags.auto_pass.mode == 'start_only')
                "
                class="pt-3 text-green d-flex flex-column align-items-center"
              >
                <i class="flaticon-play-circle" @click="autoApprove" />
                <span class="font-sm">start</span>
              </div>
              <div class="text-center" id="active-pass-timer-time-in-pass">
                {{ timeInPass }}
              </div>
              <div
                v-if="pinAttemptsCount >= 3"
                class="mt-2 text-danger comments-section-warning"
              >
                This student is flagged for 3 unsuccessful PIN attempts
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="currentStatus == 'approved'"
          class="bg-white rounded-30 d-flex flex-column justify-content-between align-items-center m-3"
        >
          <div v-if="comments.length" class="active-pass-comments-cnt">
            <div class="fw-bold pb-1">Comments:</div>
            <div
              v-for="(item, index) in comments"
              :key="index"
              class="active-pass-comments pb-1"
              data-test-id="student-active-pass-comment"
            >
              <strong
                >[{{
                  $helpers.formatDate(item.created_at, "", "h:mm A")
                }}]</strong
              >
              {{ item.comment }}
              <strong
                >({{
                  item.user.first_name + " " + item.user.last_name
                }})</strong
              >
            </div>
          </div>
          <div
            class="active-pass-timer bg-white rounded-30 d-flex align-items-center justify-content-center w-100 shadow text-green"
            :class="[isPassLongRunning ? 'text-black' : '']"
          >
            <i
              v-if="
                !activePass.parent &&
                activePass.flags &&
                activePass.flags.auto_pass &&
                !activePass.flags.auto_pass.is_valid
              "
              class="fi flaticon-check me-3"
              @click="
                activePass.flags &&
                activePass.flags.auto_check_in &&
                activePass.flags.auto_check_in.is_valid == true &&
                isActiveAutoCheckInModule
                  ? showCheckInModal()
                  : ''
              "
            />
            <div
              v-if="
                !activePass.parent &&
                activePass.flags &&
                activePass.flags.auto_pass &&
                activePass.flags.auto_pass.is_valid &&
                (activePass.flags.auto_pass.mode == 'start_and_stop' ||
                  activePass.flags.auto_pass.mode == 'stop_only')
              "
              class="pt-3 d-flex flex-column align-items-center text-red"
            >
              <i class="flaticon-stop-circle" @click="autoPause" />
              <span class="font-sm">stop</span>
            </div>
            <div
              v-if="
                activePass.parent?.flags?.auto_pass?.is_valid &&
                (activePass.parent.flags.auto_pass.mode === 'start_and_stop' ||
                  activePass.parent.flags.auto_pass.mode === 'stop_only')
              "
              class="pt-3 d-flex flex-column align-items-center text-red"
            >
              <i class="flaticon-stop-circle" @click="autoPause" />
              <span class="font-sm">stop</span>
            </div>
            <div class="text-center" id="active-pass-timer-time-in-pass">
              {{ timeInPass }}
            </div>
            <div
              v-if="
                !activePass.parent &&
                activePass.flags &&
                activePass.flags.auto_check_in &&
                activePass.flags.auto_check_in.is_valid == true &&
                isActiveAutoCheckInModule
              "
              class="auto-checkin text-green w-100 font-sm text-center"
              @click="showCheckInModal()"
            >
              Check-in
            </div>
            <div
              v-if="pinAttemptsCount >= 3"
              class="mt-2 text-danger comments-section-warning"
            >
              This student is flagged for 3 unsuccessful PIN attempts
            </div>
          </div>
        </div>
        <div
          v-if="currentStatus == 'expired'"
          class="bg-white rounded-30 d-flex flex-column justify-content-between align-items-center m-3"
        >
          <div v-if="comments.length" class="active-pass-comments-cnt">
            <div class="fw-bold pb-1">Comments:</div>
            <div
              v-for="(item, index) in comments"
              :key="index"
              class="active-pass-comments pb-1"
              data-test-id="student-active-pass-comment"
            >
              <strong
                >[{{
                  $helpers.formatDate(item.created_at, "", "h:mm A")
                }}]</strong
              >
              {{ item.comment }}
              <strong v-if="item.user && item.user !== 0"
                >({{
                  item.user.first_name + " " + item.user.last_name
                }})</strong
              >
              <strong v-else>(System)</strong>
            </div>
          </div>
          <div
            class="active-pass-timer bg-white rounded-30 d-flex align-items-center justify-content-center w-100 shadow text-red"
          >
            <span class="flaticon-finish-line-optimized me-3"
              ><span class="path1" /><span class="path2" /><span
                class="path3" /><span class="path4" /><span
                class="path5" /><span class="path6" /><span
                class="path7" /><span class="path8" /><span
                class="path9" /><span class="path10"
            /></span>
            {{
              activePass.parent
                ? activePass.parent.total_time
                : activePass.total_time
            }}
            <div
              v-if="pinAttemptsCount >= 3"
              class="mt-2 text-danger comments-section-warning"
            >
              This student is flagged for 3 unsuccessful PIN attempts
            </div>
          </div>
        </div>
        <!-- Parent -->
        <div v-if="activePass.parent">
          <!-- P - Wating -->
          <hr class="bg-white" id="active-pass-hr" />
          <div
            v-if="currentStatus == 'waiting'"
            class="d-flex justify-content-around px-4 px-xl-5"
          >
            <div class="from-cnt d-flex flex-column">
              <span class="text-white pb-2 fw-bold"> From:</span>
              <div
                class="ft-data text-white"
                data-test-id="student-active-pass-from"
              >
                {{
                  activePass.parent.from.first_name +
                  " " +
                  activePass.parent.from.last_name
                }}
              </div>
              <div
                v-if="activePass.parent.approved_at"
                class="approved-at text-white p-2 text-center"
              >
                {{
                  $helpers.transformDate(
                    activePass.parent.approved_at,
                    "hh:mm A"
                  )
                }}
              </div>
              <div
                v-if="activePass.parent.approved_by_user"
                :class="[
                  activePass.parent.from.id ==
                  activePass.parent.approved_by_user.id
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
              >
                {{
                  activePass.parent.approved_by_user.first_name +
                  " " +
                  activePass.parent.approved_by_user.last_name
                }}
              </div>
            </div>
            <div class="to-cnt d-flex flex-column">
              <span class="text-white pb-2 fw-bold">To:</span>
              <div
                class="ft-data text-white"
                data-test-id="student-active-pass-to"
              >
                {{
                  activePass.parent.to.first_name +
                  " " +
                  activePass.parent.to.last_name
                }}
              </div>
              <div
                v-if="activePass.parent.completed_at"
                class="approved-at text-white p-2 text-center"
                data-test-id="student-active-pass-completed-at"
              >
                {{
                  $helpers.transformDate(
                    activePass.parent.completed_at,
                    "hh:mm A"
                  )
                }}
              </div>
              <div
                v-if="activePass.parent.completed_by_user"
                :class="[
                  activePass.parent.to.id ==
                  activePass.parent.completed_by_user.id
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.parent.approved_by_user.first_name +
                  " " +
                  activePass.parent.approved_by_user.last_name
                }}
              </div>
            </div>
          </div>
          <!-- P - Approved -->
          <div
            v-if="currentStatus == 'approved'"
            class="d-flex justify-content-around px-4 px-xl-5"
          >
            <div class="from-cnt d-flex flex-column">
              <span
                class="text-white pb-2 fw-bold"
                :class="[isPassLongRunning ? 'text-black' : '']"
              >
                From:</span
              >
              <div
                class="ft-data text-white"
                :class="[isPassLongRunning ? 'text-border-black' : '']"
                data-test-id="student-active-pass-from"
              >
                {{
                  activePass.parent.from.name
                    ? activePass.parent.from.name
                    : activePass.parent.from.first_name +
                      " " +
                      activePass.parent.from.last_name
                }}
              </div>
              <div
                v-if="activePass.parent.approved_at"
                class="approved-at text-white p-2 text-center"
                :class="[isPassLongRunning ? 'text-black' : '']"
                data-test-id="student-active-pass-approved-at"
              >
                {{
                  $helpers.transformDate(
                    activePass.parent.approved_at,
                    "hh:mm A"
                  )
                }}
              </div>
              <div v-if="!activePass.parent.approved_with" class="text-center">
                <div
                  v-if="
                    activePass.parent.approved_by_user &&
                    activePass.parent.from_type == 'App\\Models\\Room'
                  "
                  :class="[
                    activePass.parent.from.user_ids_assigned.includes(
                      activePass.parent.approved_by_user.id
                    )
                      ? 'bg-gray'
                      : 'bg-red',
                    'approved-by',
                    'text-center'
                  ]"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.approved_by_user.first_name +
                    " " +
                    activePass.parent.approved_by_user.last_name
                  }}
                </div>
                <div
                  v-if="
                    activePass.parent.approved_by_user &&
                    activePass.parent.from.id !=
                      activePass.parent.approved_by_user.id &&
                    activePass.parent.from_type == 'App\\Models\\User'
                  "
                  :class="['bg-red', 'approved-by', 'text-center']"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.approved_by_user.first_name +
                    " " +
                    activePass.parent.approved_by_user.last_name
                  }}
                </div>
              </div>
              <div
                v-if="activePass.parent.approved_with"
                :class="['bg-gray', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                Auto Pass
              </div>
            </div>
            <div class="to-cnt d-flex flex-column">
              <span
                class="text-white pb-2 fw-bold"
                :class="[isPassLongRunning ? 'text-black' : '']"
                >To:</span
              >
              <div
                class="ft-data text-white"
                :class="[isPassLongRunning ? 'text-border-black' : '']"
                data-test-id="student-active-pass-to"
              >
                {{
                  activePass.parent.to.name
                    ? activePass.parent.to.name
                    : activePass.parent.to.first_name +
                      " " +
                      activePass.parent.to.last_name
                }}
              </div>
              <div
                v-if="activePass.parent.completed_at"
                class="approved-at text-white p-2 text-center"
                :class="[isPassLongRunning ? 'text-black' : '']"
                data-test-id="student-active-pass-completed-at"
              >
                {{
                  $helpers.transformDate(
                    activePass.parent.completed_at,
                    "hh:mm A"
                  )
                }}
              </div>
              <div v-if="!activePass.parent.ended_with" class="text-center">
                <div
                  v-if="
                    activePass.parent.completed_by_user &&
                    activePass.parent.to_type == 'App\\Models\\Room'
                  "
                  :class="[
                    activePass.parent.to.user_ids_assigned.includes(
                      activePass.parent.completed_by_user.id
                    )
                      ? 'bg-gray'
                      : 'bg-red',
                    'approved-by',
                    'text-center'
                  ]"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.completed_by_user.first_name +
                    " " +
                    activePass.parent.completed_by_user.last_name
                  }}
                </div>
                <div
                  v-if="
                    activePass.parent.completed_by_user &&
                    activePass.parent.to.id !=
                      activePass.parent.completed_by_user.id &&
                    activePass.parent.to_type == 'App\\Models\\User'
                  "
                  :class="['bg-red', 'approved-by', 'text-center']"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.completed_by_user.first_name +
                    " " +
                    activePass.parent.completed_by_user.last_name
                  }}
                </div>
              </div>
              <div
                v-if="activePass.parent.ended_with == 'autopass'"
                :class="['bg-gray', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                Auto Pass
              </div>
              <div
                v-if="activePass.parent.ended_with == 'checkin'"
                :class="['bg-gray', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                Check-in
              </div>
            </div>
          </div>
          <!-- P - Expired -->
          <div
            v-if="currentStatus == 'expired'"
            class="d-flex justify-content-around px-4 px-xl-5"
          >
            <div class="from-cnt d-flex flex-column">
              <span class="text-white pb-2 fw-bold"> From:</span>

              <div
                class="ft-data text-white"
                data-test-id="student-active-pass-from"
              >
                {{
                  activePass.parent.from.name
                    ? activePass.parent.from.name
                    : activePass.parent.from.first_name +
                      " " +
                      activePass.parent.from.last_name
                }}
              </div>
              <div
                v-if="activePass.parent.approved_at"
                class="approved-at text-white p-2 text-center"
                data-test-id="student-active-pass-approved-at"
              >
                {{
                  $helpers.transformDate(
                    activePass.parent.approved_at,
                    "hh:mm A"
                  )
                }}
              </div>
              <div v-if="!activePass.parent.approved_with" class="text-center">
                <div
                  v-if="
                    activePass.parent.approved_by_user &&
                    activePass.parent.from_type == 'App\\Models\\Room'
                  "
                  :class="[
                    activePass.parent.from.user_ids_assigned.includes(
                      activePass.parent.approved_by_user.id
                    )
                      ? 'bg-gray'
                      : 'bg-red',
                    'approved-by',
                    'text-center'
                  ]"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.approved_by_user.first_name +
                    " " +
                    activePass.parent.approved_by_user.last_name
                  }}
                </div>
                <div
                  v-if="
                    activePass.parent.approved_by_user &&
                    activePass.parent.from_type == 'App\\Models\\User' &&
                    activePass.parent.from.id !=
                      activePass.parent.approved_by_user.id
                  "
                  :class="['bg-red', 'approved-by', 'text-center']"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.approved_by_user.first_name +
                    " " +
                    activePass.parent.approved_by_user.last_name
                  }}
                </div>
              </div>
              <div
                v-if="activePass.parent.approved_with"
                :class="['bg-gray', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                Auto Pass
              </div>
              <div
                v-if="
                  isPassSystemEnded &&
                  activePass.parent.approved_at &&
                  !activePass.parent.approved_by_user
                "
                :class="['bg-red', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                System Ended
              </div>
            </div>
            <div class="to-cnt d-flex flex-column">
              <span class="text-white pb-2 fw-bold">To:</span>
              <div
                class="ft-data text-white"
                data-test-id="student-active-pass-to"
              >
                {{
                  activePass.parent.to.name
                    ? activePass.parent.to.name
                    : activePass.parent.to.first_name +
                      " " +
                      activePass.parent.to.last_name
                }}
              </div>
              <div
                v-if="activePass.parent.completed_at"
                class="approved-at text-white p-2 text-center"
                data-test-id="student-active-pass-completed-at"
              >
                {{
                  $helpers.transformDate(
                    activePass.parent.completed_at,
                    "hh:mm A"
                  )
                }}
              </div>
              <div v-if="!activePass.parent.ended_with" class="text-center">
                <div
                  v-if="
                    activePass.parent.completed_by_user &&
                    activePass.parent.to_type == 'App\\Models\\Room'
                  "
                  :class="[
                    activePass.parent.to.user_ids_assigned.includes(
                      activePass.parent.completed_by_user.id
                    )
                      ? 'bg-gray'
                      : 'bg-red',
                    'approved-by',
                    'text-center'
                  ]"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.completed_by_user.first_name +
                    " " +
                    activePass.parent.completed_by_user.last_name
                  }}
                </div>
                <div
                  v-if="
                    activePass.parent.completed_by_user &&
                    activePass.parent.to.id !=
                      activePass.parent.completed_by_user.id &&
                    activePass.parent.to_type == 'App\\Models\\User'
                  "
                  :class="['bg-red', 'approved-by', 'text-center']"
                  data-test-id="student-active-pass-approved-by"
                >
                  {{
                    activePass.parent.completed_by_user.first_name +
                    " " +
                    activePass.parent.completed_by_user.last_name
                  }}
                </div>
              </div>
              <div
                v-if="activePass.parent.ended_with == 'autopass'"
                :class="['bg-gray', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                Auto Pass
              </div>
              <div
                v-if="activePass.parent.ended_with == 'checkin'"
                :class="['bg-gray', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                Check-in
              </div>
              <div
                v-if="
                  isPassSystemEnded &&
                  activePass.parent.completed_at &&
                  !activePass.parent.completed_by_user
                "
                :class="['bg-red', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                System Ended
              </div>
            </div>
          </div>
        </div>
        <!-- No Parent -->
        <!-- Wating -->
        <hr class="bg-white" id="active-pass-hr" />
        <div
          v-if="currentStatus == 'waiting'"
          class="d-flex justify-content-around px-4 px-xl-5"
        >
          <div class="from-cnt d-flex flex-column">
            <span class="text-white pb-2 fw-bold"> From:</span>
            <div
              class="ft-data bg-white text-red"
              @click="openStuffModal(state.actions.approved)"
              data-test-id="student-active-pass-from"
            >
              {{
                activePass && activePass.from.name
                  ? activePass.from.name
                  : activePass.from.first_name + " " + activePass.from.last_name
              }}
              <i class="fi flaticon-check-1 ms-1" />
            </div>
            <div
              v-if="activePass.approved_at"
              class="approved-at text-white p-2"
              data-test-id="student-active-pass-approved-at"
            />
            <div
              v-if="activePass.approved_by_user"
              :class="[
                activePass.from.id == activePass.approved_by_user.id
                  ? 'bg-gray'
                  : 'bg-red',
                'approved-by'
              ]"
              data-test-id="student-active-pass-approved-by"
            >
              {{
                activePass.approved_by_user.first_name +
                " " +
                activePass.approved_by_user.last_name
              }}
            </div>
          </div>
          <div class="to-cnt d-flex flex-column">
            <span class="text-white pb-2 fw-bold">To:</span>
            <div
              class="ft-data text-white"
              data-test-id="student-active-pass-to"
            >
              {{
                activePass.to.name
                  ? activePass.to.name
                  : activePass.to.first_name + " " + activePass.to.last_name
              }}
            </div>
          </div>
        </div>
        <!-- Approved -->
        <div
          v-if="currentStatus == 'approved' && !activePass.approved_by_user"
          class="d-flex justify-content-around px-4 px-xl-5"
        >
          <div class="from-cnt d-flex flex-column">
            <span
              class="text-white pb-2 fw-bold"
              :class="[isPassLongRunning ? 'text-black' : '']"
            >
              From:</span
            >
            <div
              class="ft-data text-green bg-white"
              :class="[isPassLongRunning ? 'text-border-black' : '']"
              @click="openStuffModal(state.actions.approved)"
              data-test-id="student-active-pass-from"
            >
              {{
                activePass.from.name
                  ? activePass.from.name
                  : activePass.from.first_name + " " + activePass.from.last_name
              }}
              <i class="fi flaticon-check-1 ms-1" />
            </div>
            <div
              v-if="activePass.approved_at"
              class="approved-at text-white p-2 text-center"
              :class="[isPassLongRunning ? 'text-black' : '']"
              data-test-id="student-active-pass-approved-at"
            >
              {{ $helpers.transformDate(activePass.approved_at, "hh:mm A") }}
            </div>
            <div v-if="!activePass.approved_with">
              <div
                v-if="activePass.approved_by_user"
                :class="[
                  activePass.from.id == activePass.approved_by_user.id
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.approved_by_user.first_name +
                  " " +
                  activePass.approved_by_user.last_name
                }}
              </div>
            </div>

            <div
              v-if="activePass.approved_with"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-approved-by"
            >
              Auto Pass
            </div>
          </div>
          <div class="to-cnt d-flex flex-column">
            <span
              class="text-white pb-2 fw-bold"
              :class="[isPassLongRunning ? 'text-black' : '']"
              >To:</span
            >
            <div
              class="ft-data text-white"
              :class="[isPassLongRunning ? 'text-border-black' : '']"
              data-test-id="student-active-pass-to"
            >
              {{
                activePass.to.name
                  ? activePass.to.name
                  : activePass.to.first_name + " " + activePass.to.last_name
              }}
            </div>
            <div
              v-if="activePass.completed_at"
              class="approved-at text-white p-2 text-center"
              :class="[isPassLongRunning ? 'text-black' : '']"
              data-test-id="student-active-pass-approved-at"
            >
              {{ $helpers.transformDate(activePass.completed_at, "hh:mm A") }}
            </div>
            <div v-if="!activePass.ended_with">
              <div
                v-if="activePass.completed_by_user"
                :class="[
                  activePass.to.id == activePass.completed_by_user.id
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.approved_by_user.first_name +
                  " " +
                  activePass.approved_by_user.last_name
                }}
              </div>
            </div>
            <div
              v-if="activePass.ended_with == 'autopass'"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-approved-by"
            >
              Auto Pass
            </div>
            <div
              v-if="activePass.ended_with == 'checkin'"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-approved-by"
            >
              Check-in
            </div>
          </div>
        </div>
        <div
          v-if="currentStatus == 'approved' && activePass.approved_by_user"
          class="d-flex justify-content-around px-4 px-xl-5"
        >
          <div class="from-cnt d-flex flex-column">
            <span
              class="text-white pb-2 fw-bold"
              :class="[isPassLongRunning ? 'text-black ' : '']"
            >
              From:</span
            >
            <div
              class="ft-data text-white"
              :class="[isPassLongRunning ? 'text-border-black ' : '']"
              data-test-id="student-active-pass-from"
            >
              {{
                activePass.from.name
                  ? activePass.from.name
                  : activePass.from.first_name + " " + activePass.from.last_name
              }}
            </div>
            <div
              v-if="activePass.approved_at"
              class="approved-at text-white p-2 text-center"
              :class="[isPassLongRunning ? 'text-black' : '']"
              data-test-id="student-active-pass-approved-at"
            >
              {{ $helpers.transformDate(activePass.approved_at, "hh:mm A") }}
            </div>
            <div v-if="!activePass.approved_with" class="text-center">
              <div
                v-if="
                  activePass.approved_by_user &&
                  !activePass.approved_with &&
                  activePass.from_type == 'App\\Models\\Room'
                "
                :class="[
                  activePass.from.user_ids_assigned.includes(
                    activePass.approved_by_user.id
                  )
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.approved_by_user.first_name +
                  " " +
                  activePass.approved_by_user.last_name
                }}
              </div>
              <div
                v-if="
                  activePass.approved_by_user &&
                  !activePass.approved_with &&
                  activePass.from.id != activePass.approved_by_user.id &&
                  activePass.from_type == 'App\\Models\\User'
                "
                :class="['bg-red', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.approved_by_user.first_name +
                  " " +
                  activePass.approved_by_user.last_name
                }}
              </div>
            </div>
            <div
              v-if="activePass.approved_with"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-approved-by"
            >
              Auto Pass
            </div>
          </div>
          <div class="to-cnt d-flex flex-column">
            <span
              class="text-white pb-2 fw-bold"
              :class="[isPassLongRunning ? 'text-black' : '']"
              >To:</span
            >
            <div
              class="ft-data text-green bg-white"
              :class="[isPassLongRunning ? 'text-border-black' : '']"
              @click="openStuffModal(state.actions.end)"
              data-test-id="student-active-pass-to"
            >
              {{
                activePass.to.name
                  ? activePass.to.name
                  : activePass.to.first_name + " " + activePass.to.last_name
              }}
              <i class="fi flaticon-check-1 ms-1" />
            </div>
            <div
              v-if="activePass.completed_at"
              class="approved-at text-white p-2 text-center"
              :class="[isPassLongRunning ? 'text-black' : '']"
              data-test-id="student-active-pass-completed-at"
            >
              {{ $helpers.transformDate(activePass.completed_at, "hh:mm A") }}
            </div>
            <div v-if="!activePass.ended_with" class="text-center">
              <div
                v-if="
                  activePass.completed_by_user &&
                  activePass.to_type == 'App\\Models\\Room'
                "
                :class="[
                  activePass.to.user_ids_assigned.includes(
                    activePass.completed_by_user.id
                  )
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
                data-test-id="student-active-pass-completed-by"
              >
                {{
                  activePass.completed_by_user.first_name +
                  " " +
                  activePass.completed_by_user.last_name
                }}
              </div>
              <div
                v-if="
                  activePass.completed_by_user &&
                  activePass.to.id != activePass.completed_by_user.id &&
                  activePass.to_type == 'App\\Models\\User'
                "
                :class="['bg-red', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-completed-by"
              >
                {{
                  activePass.completed_by_user.first_name +
                  " " +
                  activePass.completed_by_user.last_name
                }}
              </div>
            </div>
            <div
              v-if="activePass.ended_with == 'autopass'"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-completed-by"
            >
              Auto Pass
            </div>
            <div
              v-if="activePass.ended_with == 'checkin'"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-completed-by"
            >
              Check-in
            </div>
          </div>
        </div>
        <!-- Exprired -->
        <div
          v-if="currentStatus == 'expired'"
          class="d-flex justify-content-around px-4 px-xl-5"
        >
          <div class="from-cnt d-flex flex-column">
            <span class="text-white pb-2 fw-bold"> From:</span>
            <div
              v-if="activePass && activePass.from"
              class="ft-data text-white"
              data-test-id="student-active-pass-from"
            >
              {{
                activePass.from.name
                  ? activePass.from.name
                  : activePass.from.first_name + " " + activePass.from.last_name
              }}
            </div>
            <div
              v-if="activePass.approved_at"
              class="approved-at text-white p-2 text-center"
              data-test-id="student-active-pass-approved-at"
            >
              {{ $helpers.transformDate(activePass.approved_at, "hh:mm A") }}
            </div>
            <div
              v-if="!activePass.approved_with"
              class="text-center"
              :class="{ 'mt-3': !activePass.approved_at }"
            >
              <div
                v-if="
                  activePass.approved_by_user &&
                  !activePass.approved_with &&
                  activePass.from_type == 'App\\Models\\Room'
                "
                :class="[
                  activePass.from.user_ids_assigned.includes(
                    activePass.approved_by_user.id
                  )
                    ? 'bg-gray'
                    : 'bg-red',
                  'approved-by',
                  'text-center'
                ]"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.approved_by_user.first_name +
                  " " +
                  activePass.approved_by_user.last_name
                }}
              </div>
              <div
                v-if="
                  activePass.approved_by_user &&
                  !activePass.approved_with &&
                  activePass.from.id != activePass.approved_by_user.id &&
                  activePass.from_type == 'App\\Models\\User'
                "
                :class="['bg-red', 'approved-by', 'text-center']"
                data-test-id="student-active-pass-approved-by"
              >
                {{
                  activePass.approved_by_user.first_name +
                  " " +
                  activePass.approved_by_user.last_name
                }}
              </div>
            </div>
            <div
              v-if="activePass.approved_with"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-approved-by"
            >
              Auto Pass
            </div>
            <div
              v-if="
                isPassSystemEnded &&
                activePass.approved_at &&
                !activePass.approved_by_user
              "
              :class="['bg-red', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-approved-by"
            >
              System Ended
            </div>
          </div>
          <div class="to-cnt d-flex flex-column">
            <span class="text-white pb-2 fw-bold">To:</span>
            <div
              v-if="activePass && activePass.to"
              class="ft-data text-white"
              data-test-id="student-active-pass-to"
            >
              {{
                activePass.to.name
                  ? activePass.to.name
                  : activePass.to.first_name + " " + activePass.to.last_name
              }}
            </div>
            <div
              v-if="activePass.completed_at"
              class="approved-at text-white p-2 text-center"
              data-test-id="student-active-pass-approved-at"
            >
              {{ $helpers.transformDate(activePass.completed_at, "hh:mm A") }}
            </div>
            <div
              v-if="!activePass.ended_with && activePass.completed_by_user"
              class="text-center"
            >
              <div v-if="activePass.to_type == 'App\\Models\\Room'">
                <!-- <div
                  :class="[activePass.to.user_ids_assigned.includes(activePass.completed_by_user.id)? 'bg-gray':'bg-red','approved-by','text-center']"
                  v-if="activePass.to.trip_type=='Roundtrip' && (activePass.approved_by !== activePass.completed_by)">
                  {{ activePass.completed_by_user.first_name + " " + activePass.completed_by_user.last_name }}
                </div> -->
                <div
                  v-if="
                    activePass.from_type === 'App\\Models\\Room' ||
                    activePass.from_id !== activePass.completed_by_user.id
                  "
                  :class="[
                    activePass.to.user_ids_assigned.includes(
                      activePass.completed_by_user.id
                    )
                      ? 'bg-gray'
                      : 'bg-red',
                    'approved-by',
                    'text-center'
                  ]"
                  data-test-id="student-active-pass-completed-by"
                >
                  {{
                    activePass.completed_by_user.first_name +
                    " " +
                    activePass.completed_by_user.last_name
                  }}
                </div>
              </div>
              <div v-else>
                <div
                  v-if="activePass.to.id != activePass.completed_by_user.id"
                  :class="['bg-red', 'approved-by', 'text-center']"
                  data-test-id="student-active-pass-completed-by"
                >
                  {{
                    activePass.completed_by_user.first_name +
                    " " +
                    activePass.completed_by_user.last_name
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="
                activePass.ended_with == 'autopass' ||
                activePass.ended_with == 'kiosk'
              "
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-completed-by"
            >
              Auto Pass
            </div>
            <div
              v-if="activePass.ended_with == 'checkin'"
              :class="['bg-gray', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-completed-by"
            >
              Check-in
            </div>
            <div
              v-if="
                isPassSystemEnded &&
                activePass.completed_at &&
                !activePass.completed_by_user
              "
              :class="['bg-red', 'approved-by', 'text-center']"
              data-test-id="student-active-pass-completed-by"
            >
              System Ended
            </div>
          </div>
        </div>
        <div class="d-flex py-5 px-5 align-items-center">
          <div
            class="pe-3 fw-bolder text-white"
            :class="[isPassLongRunning ? 'text-black' : '']"
          >
            Status:
          </div>
          <div
            class="status-block d-flex align-items-center"
            :class="[isPassLongRunning ? 'longRunning' : currentStatus]"
            data-test-id="student-active-pass-status"
          >
            <span
              v-if="currentStatus == 'waiting'"
              class="flaticon-hour-glass-2-optimized me-1"
            >
              <span class="path1" /><span class="path2" /><span
                class="path3"
              /><span class="path4" /><span class="path5" /><span
                class="path6"
              /><span class="path7" /><span class="path8" /><span
                class="path9"
              /><span class="path10" />
            </span>
            <i
              v-if="currentStatus == 'approved'"
              class="fi flaticon-check me-1"
            />
            <span
              v-if="currentStatus == 'expired'"
              class="flaticon-finish-line-optimized me-1"
              ><span class="path1" /><span class="path2" /><span
                class="path3" /><span class="path4" /><span
                class="path5" /><span class="path6" /><span
                class="path7" /><span class="path8" /><span
                class="path9" /><span class="path10"
            /></span>
            {{
              state.passStatuses[
                currentStatus == "expired" && activePass.canceled_at != null
                  ? "canceled"
                  : currentStatus
              ]
            }}
          </div>
          <div class="mx-5 text-white d-block">
            <div
              v-if="currentStatus == 'expired' && activePass.parent"
              class=""
              data-test-id="student-active-pass-status-time"
            >
              {{
                $helpers.transformDate(
                  activePass.parent.canceled_at ||
                    activePass.parent.ended_at ||
                    activePass.parent.completed_at,
                  "MM-DD-YYYY h:mm A"
                )
              }}
            </div>
            <div
              v-if="currentStatus == 'expired' && !activePass.parent"
              data-test-id="student-active-pass-status-time"
            >
              {{
                $helpers.transformDate(
                  activePass.canceled_at ||
                    activePass.ended_at ||
                    activePass.completed_at ||
                    activePass.expired_at,
                  "MM-DD-YYYY h:mm A"
                )
              }}
            </div>
          </div>
        </div>
        <div
          class="active-pass-history-badge text-white"
          @click="openHistoryModal()"
          data-test-id="student-active-pass-count"
        >
          {{ activePass.user.passes_for_today_count }}
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import moment from "moment-timezone"
import StudentPassHistory from "@/v3components/Forms/Students/StudentPassHistory"
import CancelPassModal from "@/v3components/Forms/Students/CancelPassModal"
import { useStore } from "vuex"
import { reactive, computed, onMounted, onUnmounted, watch, inject } from "vue"
import AdultAptPassCheckInModal from "@/v3components/Forms/Students/AdultAptPassCheckInModal"
import AdultAptPassActionModal from "@/v3components/Forms/Students/AdultAptPassActionModal"
import helpersJS from "@/helpers/index"

export default {
  name: "ActivePass",
  components: {},
  setup() {
    const store = useStore()

    const modal = inject("modal")

    const state = reactive({
      passStatuses: {
        waiting: "Waiting Approval",
        approved: "Active",
        expired: "Ended",
        canceled: "Canceled"
      },
      actions: {
        approved: 1,
        end: 2,
        arrived: 3,
        returning: 4,
        cancel: 5
      },
      counter: 1,
      showStuffModal: false,
      teacherPin: "",
      teacherNote: "",
      responseMessageTeacherApproval: null,
      responseMessageRoomApproval: null,
      modalAction: 1,
      isloading: false,
      updatePassHistory: true,
      showCheckInModal: false,
      showCancelModal: false,
      roomPin: ""
    })

    const activePass = computed(() => store.getters["passes/activePass"])
    const staff = computed(() => store.getters["users/formListStaff"])
    const rooms = computed(() => store.getters["rooms/formListRooms"])
    const pinAttemptsCount = computed(
      () => store.getters["authentication/pinAttemptsCount"]
    )
    const passHistories = computed(
      () => store.getters["passes/studentPassHistories"]
    )
    const comments = computed(() => store.getters["comments/comments"])
    const isActiveAutoCheckInModule = computed(
      () => store.getters["schools/isActiveAutoCheckInModule"]
    )

    const timeInRequest = computed(() => {
      if (activePass.value && activePass.value.pass_status != 0) {
        const duration = moment.duration(activePass.value.expire_after)
        let timeIn = 0
        if (!activePass.value.parent) {
          if (activePass.value.approved_at) {
            timeIn = moment(activePass.value.approved_at).valueOf()
          } else {
            timeIn = moment(activePass.value.requested_at).valueOf()
          }
        } else {
          if (activePass.value.parent.approved_at) {
            timeIn = moment(activePass.value.parent.approved_at).valueOf()
          }
        }
        // var requested_at = moment(activePass.value.requested_at).valueOf();
        const expired_at = timeIn + duration.asMilliseconds()
        const timeremain = moment(expired_at).diff(moment(), "seconds")

        if (timeremain <= 0 || !state.counter || !activePass.value) {
          return "00:00:00"
        }
        return helpersJS.secondsToTimeString(timeremain)
      }
      return null
    })

    const timeInPass = computed(() => {
      return state.counter &&
        activePass.value &&
        activePass.value.pass_status != 0
        ? helpersJS.secondsToTimeString(
            moment().diff(
              activePass.value.parent && activePass.value.parent.approved_at
                ? moment(activePass.value.parent.approved_at)
                : activePass.value.approved_at
                  ? moment(activePass.value.approved_at)
                  : moment(),
              "seconds"
            )
          )
        : ""
    })

    const fromToCollection = computed(() => {
      return staff.value && rooms.value ? staff.value.concat(rooms.value) : null
    })

    const isPassLongRunning = computed(() => {
      return (
        currentStatus.value !== "expired" &&
        ((activePass.value &&
          activePass.value.pass_status == 1 &&
          activePass.value.flagged_at !== null) ||
          (activePass.value.parent &&
            activePass.value.parent.flagged_at !== null))
      )
    })

    const currentStatus = computed(() => {
      if (
        activePass.value &&
        activePass.value.pass_status == 1 &&
        activePass.value.approved_at === null &&
        !activePass.value.parent
      ) {
        return "waiting"
      }
      if (
        activePass.value &&
        activePass.value.pass_status == 1 &&
        activePass.value.approved_at === null &&
        activePass.value.parent
      ) {
        return "approved"
      }
      if (
        activePass.value &&
        activePass.value.pass_status == 1 &&
        activePass.value.approved_at !== null &&
        activePass.value.completed_at === null
      ) {
        return "approved"
      }
      return "expired"
    })

    const isLayoverPass = computed(() => {
      return activePass.value &&
        (activePass.value.to_type == "App\\Models\\User" ||
          (activePass.value.to && activePass.value.to.trip_type == "Layover"))
        ? true
        : false
    })

    const isPassSystemEnded = computed(() => {
      if (activePass.value) {
        return helpersJS.isPassSystemEnded(activePass.value)
      }
      return null
    })

    onMounted(() => {
      state.isloading = true
      store.dispatch("passes/getStudentActivePass").then((response) => {
        state.isloading = false
        if (response.data && response.data.data && response?.data?.data?.id) {
          const data = response.data.data
          const params = {
            from_id: data.from_id,
            from_type: data.from_type,
            to_id: data.to_id,
            to_type: data.to_type
          }
          store.dispatch("passes/getActiveFlags", params)
        }
      })
      clearInterval(window.activePass)
      window.activePass = setInterval(() => {
        state.counter++
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(window.activePass)
    })

    const closeCheckInModal = () => {
      state.roomPin = ""
      state.showCheckInModal = false
    }

    const closeCancelModal = () => {
      state.showCancelModal = false
    }

    const openCancelModal = () => {
      modal.open(CancelPassModal, {
        size: "md",
        title: "",
        props: {
          passID: activePass.value.id
        }
      })
    }

    const openHistoryModal = () => {
      modal.open(StudentPassHistory, {
        size: "lg",
        title: "Today's History",
        props: {
          setData: true
        },
        help: "todays_history"
      })
    }

    const closeStuffModal = () => {
      state.showStuffModal = false
      state.responseMessageTeacherApproval = null
      state.teacherPin = ""
      state.teacherNote = ""
      v$.value.$reset()
    }

    const openStuffModal = () => {
      modal.open(AdultAptPassActionModal, {
        size: "lg",
        title: "",
        props: {
          activePass: activePass.value,
          currentStatus: currentStatus.value,
          isLayoverPass: isLayoverPass.value
        }
      })
    }

    const showCheckInModal = () => {
      modal.open(AdultAptPassCheckInModal, {
        size: "md",
        title: "",
        props: {
          activePass: activePass.value
        }
      })
    }

    const autoApprove = () => {
      store.dispatch("passes/autoApprovePass", {
        id: activePass.value.id,
        action: 1
      })
    }

    const autoPause = () => {
      store.dispatch("passes/autoApprovePass", {
        id: activePass.value.id,
        action: 2
      })
    }

    const deleteActivePassBlock = () => {
      store.commit("passBlocks/DELETE_ACTIVE_PASS_BLOCK")
    }

    const resetTeacherApproveMessage = () => {
      state.responseMessageTeacherApproval = null
    }

    watch(
      () => activePass.value,
      () => {
        const getCommentData = {
          passId: activePass.value.parent
            ? activePass.value.parent.id
            : activePass.value.id,
          commentableType: "App\\Models\\Pass"
        }
        store.dispatch("comments/getPassComments", getCommentData)
      }
    )

    const isLongRunning = (pass) => {
      return (
        pass !== "expired" &&
        ((pass && pass.pass_status == 1 && pass.flagged_at !== null) ||
          (pass?.parent && pass?.parent?.flagged_at !== null))
      )
    }

    watch(
      () => activePass.value,
      (newVal, oldVal) => {
        if (
          (!isLongRunning(oldVal) && isLongRunning(newVal)) ||
          oldVal?.flagged_at != newVal?.flagged_at
        ) {
          return
        }

        if (newVal != oldVal) {
          modal.close()
        }
      }
    )

    watch(
      () => timeInPass.value,
      () => {
        if (
          activePass.value.pass_status != 0 &&
          (activePass.value.flagged_at == null ||
            (activePass.value.parent &&
              activePass.value.parent.flagged_at == null))
        ) {
          const timeInPassArr = timeInPass.value.split(":") // split it at the colons
          const timeInPassSeconds =
            +timeInPassArr[0] * 60 * 60 +
            +timeInPassArr[1] * 60 +
            +timeInPassArr[2]

          const flagAfterArr = activePass.value.flag_after.split(":")
          const flagAfterSeconds =
            +flagAfterArr[0] * 60 * 60 +
            +flagAfterArr[1] * 60 +
            +flagAfterArr[2]

          if (timeInPassSeconds >= flagAfterSeconds) {
            store.commit("passes/SET_ACTIVE_PASS", {
              ...activePass.value,
              flagged_at: moment()
            })
            if (activePass.value.parent) {
              store.commit("passes/SET_ACTIVE_PASS", {
                ...activePass.value,
                parent: { ...activePass.value.parent, flagged_at: moment() }
              })
            }
          }
        }
      }
    )

    watch(
      () => timeInRequest.value,
      () => {
        if (
          timeInRequest.value == "00:00:00" &&
          activePass.value.pass_status != 0
        ) {
          const temp = JSON.parse(JSON.stringify(activePass.value))
          temp.pass_status = 0
          temp.ended_at = moment().format("YYYY-MM-DD HH:mm:ss")
          store.commit("passes/SET_ACTIVE_PASS", temp)
          const elem = {
            id: activePass.value.id,
            data: {
              action: 6
            }
          }
          store.dispatch("passes/updatePass", elem).then(() => {
            state.responseMessageTeacherApproval = {
              type: "success",
              message: "Successfully updated."
            }
            state.teacherPin = ""
            state.teacherNote = ""
          })
        }
      }
    )

    return {
      state,
      activePass,
      staff,
      rooms,
      pinAttemptsCount,
      passHistories,
      comments,
      isActiveAutoCheckInModule,
      timeInRequest,
      timeInPass,
      fromToCollection,
      isPassLongRunning,
      currentStatus,
      isLayoverPass,
      isPassSystemEnded,
      closeCheckInModal,
      closeCancelModal,
      openHistoryModal,
      closeStuffModal,
      openStuffModal,
      autoApprove,
      autoPause,
      deleteActivePassBlock,
      resetTeacherApproveMessage,
      openCancelModal,
      showCheckInModal
    }
  }
}
</script>
<style scoped lang="scss">
.stuff-only-popup-buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;

  @media screen and (max-width: 768px) {
    margin: 0px;
  }

  button.btn {
    display: flex;
    align-items: center;
    width: 130px !important;

    @media screen and (max-width: 768px) {
      width: 100% !important;
    }

    padding: 5px 10px !important;

    &:before,
    & .flaticon-forbidden,
    & .flaticon-cancel,
    & .flaticon-check {
      width: 25px;
      height: 25px;
      background-size: 100%;
      font-size: 23px;
    }
  }
}

.comments-section-warning {
  font-size: 14px;
}
</style>
