<template>
  <div>
    <CContainer class="student-pass">
      <CRow v-if="!state.isloading" class="justify-content-center">
        <CCol
          lg="8"
          xl="6"
          class="bg-white text-center pt-4 pb-2 position-relative"
        >
          <HelpCenterButton
            classes="top-right mt-2 me-2"
            :content-key="formHelpCenterKey"
            :is-old="true"
          />
          <InfoBox v-if="state.restrictions" class="mt-2">
            You have ({{ state.restrictions }}) location restrictions
          </InfoBox>
        </CCol>
      </CRow>
      <CRow v-if="!state.isloading" class="justify-content-center">
        <CCol lg="8" xl="6" class="bg-white text-start pt-4 pb-4 mb-5">
          <div class="d-flex flex-md-shrink-0 pass-tab-cnt">
            <button
              :class="[
                state.modal.activeModalTab === 'now-pass' ? 'focus' : '',
                !passLimits.has || !passLimits.student_reached_limit
                  ? 'active'
                  : '',
                'py-2 px-4 fw-bold text-uppercase now-pass-btn',
                ,
                hasAppoitmentPasses ? 'w-50' : 'w-100'
              ]"
              @click="setActiveTab('now-pass')"
            >
              Now Pass
              <span
                v-if="passLimits.has"
                class="d-block text-uppercase text-value-xs"
                >Pass Limit:
                {{
                  passLimits.max > passLimits.current
                    ? passLimits.max - passLimits.current
                    : 0
                }}/{{ passLimits.max == -1 ? "&infin;" : passLimits.max }}</span
              >
              <span
                v-if="passLimits.has"
                class="d-block text-uppercase text-value-xs"
                >{{ $helpers.transformDate(passLimits.from_date) }}
                -
                {{ $helpers.transformDate(passLimits.to_date) }}</span
              >
            </button>
            <button
              data-test-id="student-create-app-tab"
              v-if="hasAppoitmentPasses"
              :class="[
                state.modal.activeModalTab === 'appointment-pass'
                  ? 'focus'
                  : '',
                appointmentPassRequests > 0 && hasAppoitmentPasses
                  ? 'active'
                  : '',
                'text-uppercase py-2 px-4 fw-bold w-50 appointment-pass-btn  lh-normal'
              ]"
              @click="setActiveTab('appointment-pass')"
            >
              Appointment Pass
              <span class="d-block text-uppercase text-value-xs"
                >{{ appointmentPassRequests }} request(s) available</span
              >
            </button>
          </div>
          <div v-show="state.modal.activeModalTab === 'now-pass'" class="my-2">
            <CreatePassForm
              v-if="!passLimits.has || passLimits.current < passLimits.max"
            />
            <div v-else class="d-flex flex-column py-5">
              <img src="@/assets/images/sad-face.png" class="w-50 m-auto" />
              <div
                class="text-primary text-uppercase text-value-lg text-center"
              >
                (0) NOW PASSES AVAILABLE
              </div>
            </div>
          </div>
          <div
            v-show="
              state.showOnload ||
              state.modal.activeModalTab === 'appointment-pass'
            "
            :class="{ 'opacity-0': state.showOnload }"
          >
            <CreateAppointmentPassForm
              v-if="appointmentPassRequests > 0 && hasAppoitmentPasses"
            />
            <div v-if="!hasAppoitmentPasses" class="d-flex flex-column py-5">
              <!-- <img src="@/assets/images/sad-face.png" class="w-50 m-auto"> -->
              <div
                class="text-primary text-uppercase text-value-lg text-center"
              >
                THE MODEULE IS NOT AVAILABLE
              </div>
            </div>
            <div
              v-if="appointmentPassRequests == 0 && hasAppoitmentPasses"
              class="d-flex flex-column py-5"
            >
              <img src="@/assets/images/sad-face.png" class="w-50 m-auto" />
              <div
                class="text-primary text-uppercase text-value-lg text-center"
              >
                (0) APPOINTMENT PASSES AVAILABLE
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol lg="8" xl="6" class="">
          <CSpinner v-if="state.isloading" color="primary" />
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import CreatePassForm from "../v3components/Forms/Students/CreatePassForm"
import CreateAppointmentPassForm from "../v3components/Forms/Students/CreateAppointmentPassForm"
import HelpCenterButton from "@/v3components/HelpCenterButton"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import { reactive, computed, onMounted, onUnmounted } from "vue"
import { useStore } from "vuex"

export default {
  name: "TeacherPass",
  components: {
    CreatePassForm,
    CreateAppointmentPassForm,
    HelpCenterButton,
    InfoBox
  },
  setup() {
    const store = useStore()

    const state = reactive({
      modal: {
        isVisible: false,
        activeModalTab: "now-pass"
      },
      activeModal: null,
      notf: {
        acknowledge: null,
        appointmentpassready: null
      },
      isloading: true,
      restrictions: null,
      showOnload: true
    })

    const passes = computed(() => store.getters["passes/passes"])
    const pass_limit = computed(() => store.getters["passes/pass_limit"])
    const app_pass_limit = computed(
      () => store.getters["passes/app_pass_limit"]
    )
    const activePass = computed(() => store.getters["passes/activePass"])
    const notifications = computed(() => store.getters["sockets/notifications"])
    const activeSchoolModules = computed(
      () => store.getters["schools/activeSchoolModules"]
    )
    const currentUser = computed(() => store.getters["authentication/user"])
    const appointmentPassRequests = computed(() => {
      return app_pass_limit.value.max_count - app_pass_limit.value.created_count
    })
    const passLimits = computed(() => pass_limit.value)
    const appointmentPassReady = computed(
      () => notifications.value.appointmentPassRun.data
    )
    const acknowledgePassNotf = computed(
      () => notifications.value.appointmentPassRemind.data
    )
    const hasAppoitmentPasses = computed(() => {
      const appointmentPass = activeSchoolModules.value.find(
        (module) => module.name === "Appointmentpass" && module.pivot.status
      )
      if (!appointmentPass) {
        return false
      }
      const appointmentPassOptions = JSON.parse(
        appointmentPass.pivot.option_json
      )
      return (
        appointmentPassOptions.admin ||
        appointmentPassOptions.location ||
        appointmentPassOptions.staff ||
        appointmentPassOptions.teacher
      )
    })
    const formHelpCenterKey = computed(() => {
      switch (state.modal.activeModalTab) {
        case "now-pass":
          return "to_create_now_pass_txt"

        case "appointment-pass":
          return "to_create_an_appointment_text"

        default:
          return ""
      }
    })

    onMounted(() => {
      getPasses()
      setTimeout(() => {
        state.showOnload = false // fixes carousel dots scroll bug
      }, 1000)
      initSockets()
    })

    onUnmounted(() => {
      destroySockets()
    })

    const setActiveTab = (tab) => {
      state.modal.activeModalTab = tab
    }

    const acknowledgePass = (pass_id) => {
      store.dispatch("passes/acknowledgeStudentAppointmentPass", pass_id)
    }

    const getPasses = () => {
      store.dispatch("passes/getPasses").then((res) => {
        state.isloading = false
        if (res && res.data && res.data.data && res.data.data.restriction) {
          state.restrictions = res.data.data.restriction.count
        }
      })
    }

    const initSockets = () => {
      window.studentChannels.profileChannel.listen(
        "StudentPassLimitCreatedEvent",
        () => {
          getPasses()
        }
      )
      window.studentChannels.profileChannel.listen(
        "StudentPassLimitCreatedEvent",
        () => {
          getPasses()
        }
      )

      window.studentChannels.profileChannel.listen(
        "StudentPassLimitRemovedEvent",
        () => {
          getPasses()
        }
      )
      window.studentChannels.profileChannel.listen(
        "StudentPassLimitRemovedEvent",
        () => {
          getPasses()
        }
      )
    }

    const destroySockets = () => {
      if (process.env.VUE_APP_SOCKET_PROVIDER == "PUSHER") {
        window.studentChannels.studentPassLimits.subscription.unbind_all()
        window.studentChannels.profileChannel.subscription.unbind_all()
      } else {
        window.studentChannels.studentPassLimits.unsubscribe()
        window.studentChannels.profileChannel.unsubscribe()
      }
    }

    return {
      state,
      passes,
      pass_limit,
      activePass,
      notifications,
      activeSchoolModules,
      currentUser,
      appointmentPassRequests,
      passLimits,
      appointmentPassReady,
      acknowledgePassNotf,
      hasAppoitmentPasses,
      formHelpCenterKey,
      setActiveTab,
      acknowledgePass,
      getPasses,
      initSockets,
      destroySockets
    }
  }
}
</script>
