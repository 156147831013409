<template>
  <KioskTable />
</template>
<script>
import KioskTable from "@/v3components/Datatables/KioskTable"

export default {
  name: "Kiosks",
  components: { KioskTable }
}
</script>
