<template>
  <div v-if="user" class="mt-5">
    <div v-if="userRole !== 'student'">
      <Label>Title</Label>
      <CustomSelect
        :filterable="true"
        :close-on-select="true"
        :options="titles"
        v-model="state.form.title"
      ></CustomSelect>
    </div>
    <div class="mt-3">
      <Label required>First name</Label>
      <InputField
        :disabled="userRole === 'student'"
        placeholder="First name (35 characters max)"
        max-length="35"
        v-model="state.form.firstName"
        class="d-block"
        test-id="profile-settings-first-name"
        :invalid-feedback="errors.firstName || state.serverErrors.firstName"
      ></InputField>
    </div>
    <div class="mt-3">
      <Label required>Last name</Label>
      <InputField
        :disabled="userRole === 'student'"
        placeholder="Last name (35 characters max)"
        max-length="35"
        v-model="state.form.lastName"
        class="d-block"
        test-id="profile-settings-last-name"
        :invalid-feedback="errors.lastName || state.serverErrors.lastName"
      ></InputField>
    </div>
    <div v-if="userRole === 'student'" class="mt-3">
      <Label required>Email</Label>
      <InputField
        :disabled="userRole === 'student'"
        placeholder="Email"
        v-model="state.email"
        class="d-block"
        test-id="profile-settings-email"
      ></InputField>
    </div>
    <div v-if="isVisibleAllowAppRequest && userRole !== 'student'" class="mt-4">
      <div class="d-flex justify-content-between py-2">
        <Label>Appointment requests to you</Label>
        <CustomSwitch
          type="radio"
          v-model="state.form.allowAppRequest"
        ></CustomSwitch>
      </div>
      <InfoBox class="px-3 py-3">
        When the toggle is blue, students will be able to create appointment
        requests to you. (Slide toggle to white if you do not wish to allow.)
      </InfoBox>
    </div>
    <div v-if="userRole !== 'student'" class="mt-4">
      <div class="d-flex justify-content-between py-2">
        <Label>Passes to/from you</Label>
        <CustomSwitch
          type="radio"
          v-model="state.form.allowCreatePasses"
        ></CustomSwitch>
      </div>
      <InfoBox class="px-3 py-3">
        When the toggle is blue, adults and students will be able to create
        passes to/from you. (Slide toggle to white if you do not wish to allow.)
      </InfoBox>
    </div>
    <div
      v-if="
        activeSchool.is_premium &&
        isActiveAppoinmentModule &&
        userRole !== 'student'
      "
      class="mt-4"
    >
      <div class="d-flex justify-content-between py-2">
        <div class="me-3">
          <Label>Appointment Pass email reminders</Label>
          <InfoBox class="px-3 py-3">
            Select which Appointment Pass reminder emails to be sent to you.
            (Slide toggle to white if you wish to stop receiving some of them.)
          </InfoBox>
        </div>
        <div>
          <div class="d-flex justify-content-between py-2">
            <Label>20 - minute</Label>
            <CustomSwitch
              type="radio"
              class="ms-2"
              v-model="state.form.apt_reminder_emails['20min']"
            ></CustomSwitch>
          </div>
          <div class="d-flex justify-content-between py-2">
            <Label>5 - minute</Label>
            <CustomSwitch
              type="radio"
              class="ms-2"
              v-model="state.form.apt_reminder_emails['5min']"
            ></CustomSwitch>
          </div>
        </div>
      </div>
    </div>
    <div v-if="userRole !== 'student'" class="mt-4">
      <Label>PIN</Label>
      <InputField
        placeholder="Enter the PIN"
        min-length="4"
        max-length="6"
        v-model="state.form.pin"
        class="d-block"
        :invalid-feedback="errors.teacher_pin || state.serverErrors.teacher_pin"
      ></InputField>
      <InfoBox class="mt-2 px-3 py-3">
        Your PIN needs to be between 4 and 6 letters, numbers or characters long
        (any combination of these, but no spaces).
      </InfoBox>
    </div>
    <div
      v-if="
        userRole === 'student' &&
        isActiveKioskModule &&
        !activeSchool.hide_student_kiosk_passcode_field
      "
      class="mt-5"
    >
      <Label>Kiosk passcode</Label>
      <InputField
        placeholder="Enter passcode"
        v-model="state.kioskPasscode"
        input-type="text"
        :append-icon="state.kioskPasscodeIcon"
        @click-append-icon="onClickAppendIcon"
        :style="state.kioskInputTypeStyle"
        class="d-block"
        :invalid-feedback="
          errors.kioskPasscode || state.serverErrors.kioskPasscode
        "
      ></InputField>
    </div>
    <div>
      <InfoBox
        v-if="state.serverRes"
        class="my-4"
        :class="{ danger: Object.keys(state.serverErrors).length }"
        :title="state.serverRes.title"
        >{{ state.serverRes.message }}</InfoBox
      >
    </div>
    <div class="my-4">
      <LoadingButton
        v-if="
          userRole !== 'student' ||
          (isActiveKioskModule &&
            !activeSchool.hide_student_kiosk_passcode_field)
        "
        :is-loading="state.isProcessing"
        @click="
          userRole === 'student' && isActiveKioskModule
            ? updateKioskPasscode()
            : submit()
        "
        class="my-2 px-4"
        solid
        rounded
        >Save changes</LoadingButton
      >
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import Label from "@/v3components/shared/Form/Label"
import CustomSelect from "@/v3components/shared/Form/CustomSelect"
import InputField from "@/v3components/shared/Form/InputField"
import CustomSwitch from "@/v3components/shared/Form/CustomSwitch"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"

export default {
  name: "ProfileSettingForm",
  components: {
    Label,
    CustomSelect,
    InputField,
    CustomSwitch,
    InfoBox,
    LoadingButton
  },
  props: {
    user: {
      type: Object
    },
    avatar: {
      type: String,
      default: ""
    },
    userRole: {
      type: String,
      default: ""
    },
    activeAppoinmentModuleOptions: {
      type: Object
    },
    isActiveAppoinmentModule: {
      type: Boolean,
      default: false
    },
    isActiveKioskModule: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      form: {
        title: "",
        firstName: "",
        lastName: "",
        newPass: "",
        allowAppRequest: true,
        allowCreatePasses: true,
        apt_reminder_emails: {
          "20min": false,
          "5min": false
        },
        pin: ""
      },
      email: props.user.email,
      kioskPasscode: props.user.kiosk_passcode,
      kioskInputTypeStyle: "text-security:disc; -webkit-text-security:disc;",
      kioskPasscodeIcon: "ri-eye-off-fill",
      isProcessing: false,
      serverErrors: {},
      serverRes: null
    })

    const titles = [
      { value: null, label: "None" },
      { value: "Mr.", label: "Mr." },
      { value: "Mrs.", label: "Mrs." },
      { value: "MSgt", label: "MSgt" },
      { value: "Ms.", label: "Ms." },
      { value: "Mx.", label: "Mx." },
      { value: "Miss", label: "Miss" },
      { value: "Dr.", label: "Dr." },
      { value: "Principal", label: "Principal" },
      { value: "Assistant Principal", label: "Assistant Principal" },
      { value: "Dean of Students", label: "Dean of Students" },
      { value: "Br.", label: "Br." },
      { value: "Sr.", label: "Sr." },
      { value: "Cpt", label: "Cpt" },
      { value: "Maj", label: "Maj" },
      { value: "Lt Col", label: "Lt Col" },
      { value: "Col", label: "Col" },
      { value: "Sgt", label: "Sgt" },
      { value: "SSG", label: "SSG" },
      { value: "SFC", label: "SFC" },
      { value: "Coach", label: "Coach" },
      { value: "Rabbi", label: "Rabbi" }
    ]

    onMounted(() => {
      const profileData = {
        firstName: props.user.first_name,
        lastName: props.user.last_name,
        title:
          props.user.user_initials && props.user.user_initials !== "null"
            ? props.user.user_initials
            : titles[0],
        allowAppRequest: !!props.user.allow_appointment_requests,
        allowCreatePasses: !!props.user.allow_passes_to_me,
        pin: props.user.pin,
        apt_reminder_emails: {
          "20min": !!props.user.twenty_min_reminder,
          "5min": !!props.user.five_min_reminder
        }
      }
      state.form = Object.assign(state.form, profileData)
    })

    const validationMessages = {
      required: "This field is required"
    }
    const validations = {
      form: {
        firstName: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        lastName: {
          required: helpers.withMessage(validationMessages.required, required)
        }
      }
    }

    const v$ = useVuelidate(validations.form, state.form)

    const isFormValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const isVisibleAllowAppRequest = computed(() => {
      return (
        props.isActiveAppoinmentModule &&
        props.activeAppoinmentModuleOptions &&
        props.activeAppoinmentModuleOptions[props.userRole]
      )
    })

    const activeSchool = computed(() => store.getters["schools/activeSchool"])

    const onClickAppendIcon = () => {
      state.kioskInputTypeStyle =
        state.kioskInputTypeStyle ===
        "text-security:disc; -webkit-text-security:disc;"
          ? ""
          : "text-security:disc; -webkit-text-security:disc;"
      state.kioskPasscodeIcon =
        state.kioskPasscodeIcon === "ri-eye-off-fill"
          ? "ri-eye-fill"
          : "ri-eye-off-fill"
    }

    const submit = () => {
      if (isFormValid.value) {
        state.isProcessing = true
        let data = {
          initials: state.form.title ? state.form.title.value : null,
          first_name: state.form.firstName,
          last_name: state.form.lastName,
          allow_appointment_requests: state.form.allowAppRequest,
          allow_passes_to_me: state.form.allowCreatePasses,
          is_searchable: props.user.is_searchable,
          twenty_min_reminder: state.form.apt_reminder_emails["20min"],
          five_min_reminder: state.form.apt_reminder_emails["5min"]
        }
        if (state.form.newPass) {
          data.password = state.form.newPass
        }
        if (state.form.pin) {
          data.teacher_pin = state.form.pin
          data.pin = state.form.pin
        }
        if (props.avatar) {
          data.avatar = state.avatar === "delete" ? null : props.avatar
          if (data.avatar) {
            const formData = new FormData()
            for (const key in data) {
              if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key]
                formData.append(key, element)
              }
            }
            data = formData
          }
        }
        updateProfile(data)
      } else {
        v$.value.$touch()
      }
    }

    const updateProfile = (data) => {
      store
        .dispatch("authentication/updateUserProfile", data)
        .then(() => {
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          store.dispatch("authentication/getUserDetails", {
            clearCacheEntry: true
          })
          store.dispatch("authentication/getUserSettings", {
            clearCacheEntry: true
          })
          setTimeout(() => {
            setResponseInfoBox()
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const updateKioskPasscode = () => {
      store
        .dispatch("authentication/updateKioskPass", state.kioskPasscode)
        .then(() => {
          setResponseInfoBox("Success", "Successfully saved!")
          state.isProcessing = false
          state.serverErrors = {}
          store.dispatch("authentication/getUserDetails", {
            clearCacheEntry: true
          })
          store.dispatch("authentication/getUserSettings", {
            clearCacheEntry: true
          })
          setTimeout(() => {
            setResponseInfoBox()
            state.kioskPasscode = null
          }, 1800)
        })
        .catch((err) => {
          const res = err.response.data
          state.serverErrors = res.errors ? res.errors : {}
          setResponseInfoBox("Error", res.message)
          state.isProcessing = false
        })
    }

    const setResponseInfoBox = (title, message) => {
      if (title || message) {
        state.serverRes = {
          message,
          title
        }
      } else {
        state.serverRes = null
      }
    }

    return {
      state,
      titles,
      v$,
      isFormValid,
      errors,
      isVisibleAllowAppRequest,
      activeSchool,
      onClickAppendIcon,
      submit,
      updateKioskPasscode
    }
  }
}
</script>
