<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center mt-4">
        <CCol md="12 text-center">
          <DetentionsAndAppointmentsTable> </DetentionsAndAppointmentsTable>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import DetentionsAndAppointmentsTable from "@/v3components/Datatables/DetentionsAndAppointmentsTable"

export default {
  name: "TardyDetentionsAndAppointments",
  components: { DetentionsAndAppointmentsTable }
}
</script>
