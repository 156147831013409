<template>
  <div>
    <CContainer>
      <LimitLocationAvailabilityTable />
    </CContainer>
  </div>
</template>
<script>
import LimitLocationAvailabilityTable from "@/v3components/Datatables/LimitLocationAvailabilityTable"

export default {
  name: "LimitLocationAvailability",
  components: {
    LimitLocationAvailabilityTable
  }
}
</script>
