<template>
  <div class="container">
    <div
      v-if="userRole !== 'student'"
      class="row profile-settings justify-content-center"
    >
      <ActionPanel @tab-clicked="setActiveTab" :tabs="tabs"> </ActionPanel>
    </div>
    <div class="row justify-content-center mt-4 mb-4">
      <div class="col-md-8 v3-data-table py-5 px-4">
        <div v-show="state.activeTab == 'my-profile'">
          <Avatar
            :user="user"
            :user-role="userRole"
            @change-avatar="setAvatar"
          />
          <div>
            <ProfileSettingForm
              :user="user"
              :avatar="state.avatar"
              :user-role="userRole"
              :active-appoinment-module-options="activeAppoinmentModuleOptions"
              :is-active-appoinment-module="isActiveAppoinmentModule"
              :is-active-kiosk-module="isActiveKioskModule"
            />
          </div>
        </div>
        <div v-if="userRole !== 'student' && state.activeTab == 'affiliations'">
          <AddLocationsForm></AddLocationsForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue"
import ActionPanel from "@/v3components/shared/DataTable/ActionPanel"
import Avatar from "@/v3views/UserSettings/Avatar"
import ProfileSettingForm from "@/v3views/UserSettings/ProfileSettingForm"
import AddLocationsForm from "@/v3views/UserSettings/AddLocationsForm"

export default {
  name: "ProfileSettings",
  components: {
    ActionPanel,
    Avatar,
    ProfileSettingForm,
    AddLocationsForm
  },
  props: {
    user: {
      type: Object
    },
    userRole: {
      type: String,
      default: ""
    },
    activeAppoinmentModuleOptions: {
      type: Object
    },
    isActiveAppoinmentModule: {
      type: Boolean,
      default: false
    },
    isActiveAutoCheckInModule: {
      type: Boolean,
      default: false
    },
    isActiveKioskModule: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const state = reactive({
      avatar: null,
      activeTab: null,
      isProcessing: false,
      form: {
        locations: []
      }
    })

    const tabs = [
      {
        label: "My profile",
        value: "my-profile"
      },
      {
        label: "My locations",
        value: "affiliations"
      }
    ]

    onMounted(() => {
      state.activeTab = tabs[0].value
    })

    const setActiveTab = (tab) => {
      state.activeTab = tab.value
    }

    const setAvatar = (file) => {
      state.avatar = file ? file : state.avatar
    }

    return {
      state,
      tabs,
      setActiveTab,
      setAvatar
    }
  }
}
</script>
