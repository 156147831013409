<template>
  <div>
    <DashboardCounters />
    <RoomStats
      v-if="
        userRole === 'admin' || userRole === 'staff' || userRole === 'teacher'
      "
    />
    <Banners v-if="bannersFlag" />
    <div v-else>
      <div
        data-test-id="pass-block-banners"
        v-if="
          itemsBlocked && itemsBlocked.items && itemsBlocked.items.length !== 0
        "
        class="unavailable-alert"
      >
        <div class="content">
          <i class="fi flaticon-user-1 me-2"></i>
          The following passes are blocked until
          {{
            helpers.transformDate(
              itemsBlocked.to_date,
              "ddd, MM/DD/YYYY h:mm A"
            )
          }}:
          <div class="d-flex justify-content-center mt-2 mb-1">
            <div v-for="item in itemsBlocked.items" :key="item">
              <CCheckBox
                :label="item"
                :name="item"
                :inline="true"
                :custom="true"
                checked
                class="unavailable-alert-check-box"
              />
            </div>
          </div>
          Reason: {{ itemsBlocked.reason }}
        </div>
      </div>
      <div v-if="unavailable || isUserUnavailable" class="unavailable-alert">
        <span class="close-btn" @click="closeUnavailableAlert"
          ><span class="close-icon"></span
        ></span>
        <div class="content">
          <div>
            <i class="fi flaticon-briefcase me-2"></i>
            Your Out of Office is currently on and will end on
            {{
              helpers.transformDate(
                unavailable.to_date,
                "ddd, MM/DD/YYYY h:mm A"
              )
            }}
          </div>
          <div v-if="unavailable.comment" class="comment mt-1">
            Note: {{ unavailable.comment }}
          </div>
        </div>
      </div>
      <div v-if="showExceededIncidentsBanner" class="unavailable-alert">
        <div class="content">
          Student(s) have exceeded your Consequence Ladder.
        </div>
      </div>
      <DownloadStudentIDCardsBanner
        :student-id-cards-link="studentIdCardsLink"
      />
    </div>
    <CContainer>
      <CRow>
        <CCol md="12" class="text-end py-2">
          <HelpCenterButton
            classes="me-2"
            content-key="dashboard_overview"
            :is-old="true"
          />
        </CCol>
      </CRow>
    </CContainer>
    <div class="pt-0 p-sm-2">
      <DashboardDataTable />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue"
import { useStore } from "vuex"
import helpers from "@/helpers/index"
import RoomStats from "@/v3components/Dashboard/RoomStatsOld"
import DashboardDataTable from "@/v3components/Datatables/DashboardDataTable/DashboardDataTableOld"
import HelpCenterButton from "@/v3components/HelpCenterButton"
import DashboardCounters from "@/v3components/Dashboard/DashboardCountersOld"
import { isV3ComponentVisible } from "@/helpers/featureFlags.js"
import DownloadStudentIDCardsBanner from "@/v3components/Dashboard/DownloadStudentIDCardsBannerOld"

export default {
  name: "DashboardOld",
  components: {
    RoomStats,
    HelpCenterButton,
    DashboardCounters,
    DashboardDataTable,
    DownloadStudentIDCardsBanner
  },

  setup() {
    const store = useStore()

    const bannersFlag = computed(() => {
      return isV3ComponentVisible("Banners")
    })

    const studentIdCardsLink = computed(() => {
      return store.getters["users/studentIdCardsLink"]
    })

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })

    const isUserUnavailable = computed(() => {
      return store.getters["authentication/isUserUnavailable"]
    })

    const unavailable = computed(() => {
      return store.getters["dashboardTable/unavailable"]
    })

    const passBlock = computed(() => {
      return store.getters["dashboardTable/passBlock"]
    })

    const user = computed(() => {
      return store.getters["authentication/user"]
    })

    const exceededIncidents = computed(() => {
      return store.getters["exceededIncidents/exceededIncidents"]
    })

    const isActiveTardyModule = computed(() => {
      return store.getters["schools/isActiveTardyModule"]
    })

    const itemsBlocked = computed(() => {
      return passBlock.value ? passBlock.value : {}
    })

    const showExceededIncidentsBanner = computed(() => {
      return exceededIncidents.value && userRole.value === "admin"
    })

    const getAllStudentIDCards = () => {
      store.dispatch("users/getAllStudentIDCardsPDF")
    }

    onMounted(() => {
      store.dispatch("dashboardCounters/getPassesForCounters")
      store.dispatch("dashboardTable/getDashboard")
      if (isActiveTardyModule.value && userRole.value === "admin") {
        store.dispatch("exceededIncidents/getExceededIncidents")
      }
      if (userRole.value === "admin" || userRole.value === "superadmin") {
        getAllStudentIDCards()
      }
    })

    const closeUnavailableAlert = () => {
      if (
        window.confirm("Are you sure you want to delete this Out Of Office?")
      ) {
        endUnavailable(unavailable.value)
      }
    }

    const endUnavailable = (unavailable) => {
      if (unavailable && unavailable.id && unavailable.status) {
        store.dispatch("outOfOfficePasses/updateOutOfOfficeStatus", {
          id: unavailable.id,
          params: { dashboard: true }
        })
      }
    }

    return {
      bannersFlag,
      helpers,
      userRole,
      isUserUnavailable,
      unavailable,
      passBlock,
      user,
      exceededIncidents,
      isActiveTardyModule,
      itemsBlocked,
      showExceededIncidentsBanner,
      closeUnavailableAlert,
      endUnavailable,
      studentIdCardsLink
    }
  }
}
</script>
