<template>
  <div>
    <CContainer>
      <CRow class="d-flex align-items-center justify-content-center">
        <CCol
          col="8"
          sm="10"
          md="9"
          lg="8"
          xl="7"
          class="v3teacher-pass pt-4 pb-4 rounded-5 bg-white"
        >
          <CreateTeacherPassForm is-in-view="true" />
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>
<script>
import CreateTeacherPassForm from "@/v3components/Forms/CreateTeacherPassForm"

export default {
  name: "TeacherPass",
  components: { CreateTeacherPassForm }
}
</script>
